import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import titleStyles from 'styles/components/pages.module.scss';
import styles from 'styles/domain/sendToGc/send-to-gc.module.scss';
import Button from 'components/inputs/Button';
import SelectField from 'components/forms/SelectField';
import TextField from 'components/forms/TextField';
import TextAreaField from 'components/forms/TextAreaField';
import MultiFileField from 'components/forms/MultiFileField';
import {
  getFinancialCenters,
  getSelectInformation,
  getTransactionTypes,
  getDemandTypes,
} from 'domain/sendToGc/utils/getSelectInformation';
import { useGetAdvisor, useGetAdvisorOfOffice } from 'domain/sendToGc/hooks/getAdvisor';
import {
  SendToGCInput,
  sendToGCInputSchema,
} from 'domain/sendToGc/schemas/sendToGCInput';
import type { SelectOption } from 'schemas/selectOption';
import WarningBar, { WarningLevel } from 'components/shared/WarningBar';
import React, { useEffect, useMemo, useState } from 'react';
import useSendFilesToGc from 'domain/sendToGc/hooks/useSendFilesToGc';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import DateField from 'components/forms/DateOnlyField';
import moment from 'moment/moment';
import 'moment-timezone';
import useIsTester from '../hooks/useIsTester';
import EquisoftFormDocument from 'domain/equisoft/component/EquisoftFormDocument';
import { EquisoftDocument } from 'domain/equisoft/schemas/equisoftDocumentSchema';
import CheckBoxField from 'components/forms/CheckBoxField';
import useGetEquisoftClient from 'domain/equisoft/hooks/useGetEquisoftClient';
import { EquisoftClient } from 'types/equisoftClient';
import useCreateEquisoftDocument from 'domain/equisoft/hooks/useCreateEquisoftDocument';
import useGetEquisoftConfig from 'domain/equisoft/hooks/useGetEquisoftConfig';

const oldBaoLink = `${process.env.REACT_APP_OLD_BAO_BASE_LINK}${process.env.REACT_APP_OLD_BAO_HOME_LINK}`;

function SendToGCPage() {
  const { t, i18n } = useTranslation('sendToGc');

  const { title, titleNoBorder, titleLinks } = titleStyles;
  const { wrapper, selectWrapper, sendButton, errorMessage } = styles;
  const isTester = useIsTester();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty },
    resetField,
    reset,
    setValue,
  } = useForm<SendToGCInput>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    resolver: zodResolver(sendToGCInputSchema),
    defaultValues: {
      selectedBusiness: undefined as SelectOption | undefined,
      selectedCategory: undefined as SelectOption | undefined,
      selectedFinancialCenter: undefined as SelectOption | undefined,
      notes: '',
      notesForAdvisorOnly: '',
      clientName: '',
      transactionTypes: [] as SelectOption[],
      demandTypes: [] as SelectOption[],
      transactionAmount: '',
      transactionDate: new Date(),
      advisorLicenseNumber: undefined as SelectOption | undefined,
      files: [] as File[],
      sendToEquisoft: false,
      clientNameFromEquisoft: undefined as SelectOption | undefined,
      equisoftDocuments: [] as EquisoftDocument[],
    },
  });

  const defaultDate = new Date();

  const { businessOptions, categoryOptionsForInsuranceAndCollectiveAnnuities, categoryOptionsForSellingTeams, categoryOptionsForTransaction, categoryOptionsForOthers } = getSelectInformation(t);
  const { financialCenters } = getFinancialCenters(t);
  const { availableTransactionTypes } = getTransactionTypes(t);
  const { demandTypes } = getDemandTypes(t);

  const { mutate: sendToGC, isLoading } = useSendFilesToGc(reset);
  const { data: currentAdvisor } = useGetAdvisor();
  const { data: currentAdvisorsOfOffice } = useGetAdvisorOfOffice();

  const watchSelectedBusiness = watch('selectedBusiness');
  const watchSelectedCategory = watch('selectedCategory');
  const watchSelectedDemandTypes = watch('demandTypes');
  const watchTransactionAmount = watch('transactionAmount');
  const watchTransactionDate = watch('transactionDate');
  const isTransactionDateIsValid = moment().subtract(1, 'd').isBefore(watchTransactionDate);
  const isTransactionDateDelayed = watchTransactionDate != defaultDate && moment().add(1, 'd').isBefore(watchTransactionDate);
  const isTransactionAmountValid = watchTransactionAmount != '';

  const watchFiles = watch('files');

  const equisoftDocuments = watch('equisoftDocuments');

  const setEquisoftDocument = (newEquisoftDocuments: EquisoftDocument[]) => {
    setValue('equisoftDocuments', newEquisoftDocuments);
    setEquisoftDocumentsTemp(newEquisoftDocuments);
  };
  //Temp state for the equisoft Doc, The data data are transfert in the real object before submit form
  const [equisoftDocumentsTemp, setEquisoftDocumentsTemp] = useState([] as EquisoftDocument[]);
  const [isEquisoftConfigure, setIsEquisoftConfigure] = useState(false);
  const sendToEquisoft = watch('sendToEquisoft');
  const watchClientNameFromEquisoft = watch('clientNameFromEquisoft');
  const { data: equisoftClient } = useGetEquisoftClient();
  const { mutate: createEquisoftDocument } = useCreateEquisoftDocument();
  const { data: equisoftConfig } = useGetEquisoftConfig();

  useEffect(() => {
    if (sendToEquisoft === false) {
      setEquisoftDocument([] as EquisoftDocument[]);
    }
  }, [sendToEquisoft]);

  useEffect(() => {
    setIsEquisoftConfigure(equisoftConfig !== null && equisoftConfig !== undefined);
  }, [equisoftConfig]);

  const showDemandTypeLowPriorityWarning = useMemo(
    () =>
      watchSelectedDemandTypes &&
      !watchSelectedDemandTypes.some(e => e.value === '858020000' || e.value === '858020002' || e.value === '858020003' || e.value === '858020004' ||
                                          e.value === '858020012' || e.value === '858020007' || e.value === '858020013' ) &&
       watchSelectedDemandTypes.some(e => e.value === '858020005' || e.value === '858020006' || e.value === '858020015' || e.value === '858020016' ||
                                          e.value === '858020008' || e.value === '858020010' || e.value === '858020009' ),
    [watchSelectedDemandTypes],
  );

  const showTransactionAmountRequiredError = 
      !isTransactionAmountValid && 
      watchSelectedDemandTypes &&
      watchSelectedDemandTypes.some(e => e.value == '858020000' || e.value == '858020000-1');

  const shownCategoryOptions = useMemo(() => {

      if (watchSelectedBusiness?.value === 'lifeInsurance' || watchSelectedBusiness?.value === 'livingBenefits' || watchSelectedBusiness?.value === 'protekSolutions' || watchSelectedBusiness?.value === 'mortgage')
        return categoryOptionsForSellingTeams;
      else if (watchSelectedBusiness?.value === 'others')
        return categoryOptionsForOthers;
      else if (watchSelectedBusiness?.value === 'groupInsurance')
        return categoryOptionsForInsuranceAndCollectiveAnnuities;
      else if (watchSelectedBusiness?.value === 'investments')
        return categoryOptionsForTransaction;
      else 
        return [];
    }, [watchSelectedBusiness]
  );

  const showFinancialCenterField = useMemo(
    () =>
      watchSelectedCategory?.value === 'sellingTeams',
    [watchSelectedCategory],
  );

  const showWarningBar = useMemo(
    () =>
      watchSelectedCategory?.value === 'investments' && !isTester,
    [watchSelectedCategory, watchSelectedBusiness],
  );

  const showTransactionFields = useMemo(
    () =>
      watchSelectedCategory?.value === 'transaction' && isTester,
    [watchSelectedCategory],
  );

  useEffect(() => {
    resetField('advisorLicenseNumber');
    resetField('demandTypes');
    resetField('transactionTypes');
  }, [watchSelectedBusiness, watchSelectedCategory]);

  useEffect(() => {
    if (watchSelectedBusiness == undefined || shownCategoryOptions.length != 1)
      resetField('selectedCategory');
    else
      setValue('selectedCategory', shownCategoryOptions[0]);
  }, [watchSelectedBusiness]);

  const showMissingAdvisorInfo = currentAdvisor == null && currentAdvisorsOfOffice == null;

  const advisors =
    currentAdvisor != null
      ? [
          {
            value: currentAdvisor.licenseNumber,
            label: currentAdvisor.fullname + '  (' + currentAdvisor.licenseNumber + ')',
          },
        ]
      : currentAdvisorsOfOffice != null
      ? currentAdvisorsOfOffice.map(x => ({
          value: x.licenseNumber,
          label: x.fullname + '  (' + x.licenseNumber + ')',
        }))
      : [];

  useEffect(() => {
    if (advisors.length === 1 && showTransactionFields) {
      resetField('advisorLicenseNumber', { defaultValue: advisors[0] });
    } else {
      resetField('advisorLicenseNumber', { defaultValue: undefined });
    }
  }, [showTransactionFields]);

  const showTransactionWarning = useMemo(
    () => watchSelectedBusiness == undefined,
    [watchSelectedBusiness],
  );

  useEffect(() => {
    resetField('selectedFinancialCenter');
  }, [showFinancialCenterField]);

  useEffect(() => {
    setValue('clientName', watchClientNameFromEquisoft?.label || '');
  }, [watchClientNameFromEquisoft]);

  const submitForm = (data: SendToGCInput) => {

    if (showTransactionFields && showTransactionAmountRequiredError) {
      return;
    }

    if (sendToEquisoft === true && equisoftDocuments) {
      if (watchClientNameFromEquisoft && data.equisoftDocuments) {
        for (let i = 0; i < equisoftDocuments.length; i++) {
          equisoftDocuments[i].sendTo = [...equisoftDocuments[i].sendTo, watchClientNameFromEquisoft]
          data.equisoftDocuments[i].sendTo = [...data.equisoftDocuments[i].sendTo, watchClientNameFromEquisoft]
        }
      }
      setValue('equisoftDocuments', equisoftDocuments);
      createEquisoftDocument(equisoftDocuments);
    }
    sendToGC(data);
  };

  return (
    <form onSubmit={handleSubmit(data => submitForm(data))}>
      <h1 className={`${title} ${titleNoBorder}`}>{t('sendToGc')}</h1>

      {showTransactionFields && (
        <div className={`${titleLinks}`}>
          <span>Consulter nos guides</span>
          <span><a href="https://youtu.be/KvM3XEUNdlM" target='_blank'><img src="youtube.png" height="22" /></a></span>
          <span><a href="documents/BAO2.0_Transactions-Investissement_cheatsheet.pdf" target='_blank'><img src="icon-pdf.png" height="20" width="20" /></a></span>
        </div>
      )}

      {showWarningBar && (
        <WarningBar message={t('warningMessage')} level={WarningLevel.IMPORTANT} link={oldBaoLink} isClosable={false} />
      )}

      <div className={wrapper}>
        <div className={selectWrapper}>
          <SelectField
            control={control}
            name="selectedBusiness"
            options={businessOptions}
            label={t('businessDivision')}
            isRequired
          />
          <SelectField
            control={control}
            name="selectedCategory"
            options={shownCategoryOptions}
            label={t('category')}
            isRequired
          />
          {showFinancialCenterField && (
            <SelectField
              control={control}
              name="selectedFinancialCenter"
              options={financialCenters}
              label={t('financialCenter')}
              isRequired
            />
          )}
        </div>

        {showTransactionFields && showTransactionWarning && (
          <span className={errorMessage}>{t('transactionWarningOneBusinessDivisionRequired')}</span>
        )}

        {showTransactionFields && (
          <SelectField
            control={control}
            name="transactionTypes"
            options={availableTransactionTypes}
            label={t('transactionTypes')}
            isMulti
            isRequired
          />
        )}
        {showTransactionFields && (
          <SelectField
            control={control}
            name="demandTypes"
            options={demandTypes}
            label={t('demandTypes')}
            isMulti
            isRequired
          />
        )}

        {showTransactionFields && showDemandTypeLowPriorityWarning && (
          <span className={errorMessage}>{t('showDemandTypeLowPriorityWarning')}</span>
        )}

        {showTransactionFields && (
          <TextField
            control={control}
            name="transactionAmount"
            label={t('transactionAmount')}
            placeholder={t('transactionAmountPlaceholder')}
            errorMessage={showTransactionAmountRequiredError ? t('transactionAmountRequiredError') : ''}
          />
        )}

        {showTransactionFields && (
          <DateField
            locale={i18n.language}
            control={control}
            name="transactionDate"
            label={`${t('transactionDate')}`}
            minDate={new Date()}
            isRequired
            errorMessage={!isTransactionDateIsValid ? t('transactionDateMustBeTodayOrAfter') : ''}
          />
        )}

        {isTransactionDateDelayed && (
          <span className={errorMessage}>{t('delayedTransactionInformationMessage')}</span>
        )}

        {showTransactionFields && !showMissingAdvisorInfo && (
          <SelectField
            control={control}
            name="advisorLicenseNumber"
            options={advisors}
            label={t('advisorLicenseNumber')}
            isRequired
          />
        )}

        {showTransactionFields && showMissingAdvisorInfo && (
          <span className={errorMessage}>{t('transactionWarningAdvisorCantBeAssigned')}</span>
        )}

        {showTransactionFields && isEquisoftConfigure && (
          <CheckBoxField control={control} name="sendToEquisoft" label={t('sendToEquisoft')} id="sendToEquisoft" />
        )}

        {isEquisoftConfigure && sendToEquisoft ? (
          <SelectField
            control={control}
            name="clientNameFromEquisoft"
            options={equisoftClient}
            label={t('clientIdentification')}
            isRequired
          />
        ) : (
          <TextField
            control={control}
            name="clientName"
            label={t('clientIdentification')}
            placeholder={t('clientIdentification')}
            isRequired
          />
        )}

        <TextAreaField control={control} name="notes" label={t('notes')} placeholder={t('notesPlaceholder')} />

        <TextAreaField control={control} name="notesForAdvisorOnly" label={t('notesForAdvisorOnly')} placeholder={t('notesForAdvisorOnlyPlaceholder')} />

        <MultiFileField
          control={control}
          name="files"
          label={t('files')}
          fileDropMessage={t('dragDropMessage')}
          isRequired={showTransactionFields}
        />

        {showTransactionFields && isEquisoftConfigure && sendToEquisoft && (
          <EquisoftFormDocument
            files={watchFiles}
            setEquisoftDocument={setEquisoftDocument}
            equisoftDocuments={equisoftDocumentsTemp}
            control={control}
            equisoftClient={equisoftClient as EquisoftClient}
          />
        )}

        <Button
          type="submit"
          className={sendButton}
          disabled={showWarningBar}
        >
          {isLoading ? <LoadingSpinner isMini isWhite /> : t('send')}
        </Button>

      </div>
    </form>
  );
}

export default SendToGCPage;
